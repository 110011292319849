import { APIResponse, FileItem } from '../redux/common/definitions';
import { AcademyContactData, AcademyData, AcademyIban } from '../redux/academy/definitions';
import { apiCall, apiCallBlob, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

interface SuccessResult {
  data: string;
}

export const getAcademyDataApi = async (id: number): Promise<APIResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<APIResponse>(`${baseURL}/academy/${id}`, init);
};

export const getAcademiesCenterList = async (): Promise<AcademyData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: AcademyData[] }>(`${baseURL}/academy`, init);
  return data;
};

export const getAllAcademies = async (): Promise<AcademyData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: AcademyData[] }>(`${baseURL}/academy/all`, init);
  return data;
};

export const launchImportAcademies = async (user_id: number): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
  };

  return await apiCall<APIResponse>(`${baseURL}/academy/import/${user_id}`, init);
};

export const getAcademyContactsList = async (academy_id: number): Promise<AcademyContactData[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: AcademyContactData[] }>(`${baseURL}/academy/${academy_id}/staff`, init);
  return data;
};

export const replaceAcademyContactsList = async (
  contactList: AcademyContactData[],
  academy_id: number,
): Promise<AcademyContactData[]> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(contactList),
  };
  const { data } = await apiCall<{ data: any[] }>(`${baseURL}/academy/${academy_id}/staff/`, init);
  return data;
};

export const createAcademyContact = async (
  contactData: AcademyContactData,
  academy_id: number,
): Promise<AcademyContactData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(contactData),
  };
  const { data } = await apiCall<{ data: AcademyContactData }>(`${baseURL}/academy/${academy_id}/staff/`, init);
  return data;
};

export const removeContactAcademy = async (academy_id: number, staff_id: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/academy/${academy_id}/staff/${staff_id}`, init);
  return data;
};

export const getAcademyContact = async (academy_id: number, staff_id: number): Promise<AcademyContactData> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: AcademyContactData }>(
    `${baseURL}/academy/${academy_id}/staff/${staff_id}`,
    init,
  );
  return data;
};

export const addAcademyIbanApi = async (
  academyIban: AcademyIban,
  academyId: number,
): Promise<Result<{ data: AcademyIban }, APIValidationError>> => {
  academyIban.academy_id = academyId;
  const init = {
    method: 'POST',
    body: JSON.stringify(academyIban),
    auth: true,
  };
  return await validatedApiCall<{ data: AcademyIban }>(`${baseURL}/academy/${academyId}/iban`, init);
};

export const academyUpdateContact = async (dataInfo: any, academy_id: number): Promise<AcademyContactData> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(dataInfo),
  };
  const { data } = await apiCall<{ data: AcademyContactData }>(
    `${baseURL}/academy/${academy_id}/staff/${dataInfo.id}`,
    init,
  );
  return data;
};

export const editAcademyIbanApi = async (
  academyIban: AcademyIban,
  acadenyId: number,
): Promise<Result<{ data: AcademyIban }, APIValidationError>> => {
  const init = {
    method: 'PUT',
    body: JSON.stringify(academyIban),
    auth: true,
  };
  return await validatedApiCall<{ data: AcademyIban }>(`${baseURL}/academy/${acadenyId}/iban/${academyIban.id}`, init);
};

export const listAcademiesIbans = async (id: number): Promise<AcademyIban[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: AcademyIban[] }>(`${baseURL}/academy/${id}/iban`, init);
  return data;
};

export const listAcademiesIbansByCode = async (code: string): Promise<AcademyIban[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  if (code) {
    const { data } = await apiCall<{ data: AcademyIban[] }>(`${baseURL}/academy/code/${code}/iban`, init);
    return data;
  } else {
    return [];
  }
};

export const getAcademyIbanApi = async (acadenyId: number, academyIbanId: number): Promise<APIResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return await apiCall<{ data: APIResponse }>(`${baseURL}/academy/${acadenyId}/iban/${academyIbanId}`, init);
};

export const removeAcademyIbanApi = async (acadenyId: number, academyIbanId: number): Promise<string> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/academy/${acadenyId}/iban/${academyIbanId}`, init);
  return data;
};

export const uploadIbanDocumentFromApi = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name);
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/academy/file/upload`, init);

  return data;
};

export const removeIbanDocumentFromApi = async (file_id: number): Promise<string> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(file_id),
    auth: true,
  };
  const { data } = await apiCall<SuccessResult>(`${baseURL}/academy/file/remove`, init);
  return data;
};

export const downloadIbanDocumentFromApi = async (file_id: number): Promise<any> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/download/${file_id}`, init);
};

export const editAcademiesIbansApi = async (academyIbans: AcademyIban[]): Promise<'OK'> => {
  const init = {
    method: 'PUT',
    body: JSON.stringify(academyIbans),
    auth: true,
  };
  const { data } = await apiCall<{ data: 'OK' }>(`${baseURL}/academy/ibans`, init);

  return data;
};
