import { apiCall } from '../../api';
import { Participant } from '../../../redux/internal-projects/participants/Participant';

const baseURL = process.env.REACT_APP_API_URL;

export const apiPostParticipant = async (
  data: Participant,
): Promise<{
  data: Participant;
}> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: Participant }>(`${baseURL}/internal_project/${data.internal_project_id}/participants`, init);
};
