import { InternalProjectId, ParticipantId } from '../../../redux/internal-projects/participants/delete/Participant';
import { apiCall } from '../../api';

const baseURL = process.env.REACT_APP_API_URL;

export const deleteParticipant = async (id: InternalProjectId, participantId: ParticipantId): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<void>(`${baseURL}/internal_project/${id}/participants/${participantId}`, init);
};
