import { apiCall } from '../api';
import { ResponseParticipantParticularitiesTypeApi } from '../../redux/internal-projects/participants/particularities/definitions';

const baseURL = process.env.REACT_APP_API_URL;

export const getInternalProjectParticipantParticularities = async (): Promise<{
  data: ResponseParticipantParticularitiesTypeApi;
}> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: ResponseParticipantParticularitiesTypeApi }>(
    `${baseURL}/internal_project/participants/particularities`,
    init,
  );
};
