import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import React from 'react';
import { SubmenuItem } from '../../components/Layout/Menus/Submenu/Submenu';
import { tScholarshipKey } from '../../helpers/translate';
import { ReactComponent as IconScholarship } from '../../images/icons/iconScholarship.svg';
import { ReactComponent as AproveIcon } from '../../images/menu/scholarship/aprobacion.svg';
import { ReactComponent as FilesIcon } from '../../images/menu/scholarship/archivos.svg';
import { ReactComponent as FinanceIcon } from '../../images/menu/scholarship/business-and-finance.svg';
import { ReactComponent as ListVerifiedIcon } from '../../images/menu/scholarship/lista-de-verificacion.svg';
import { ReactComponent as ListIcon } from '../../images/menu/scholarship/lista.svg';
import { ReactComponent as MoneyIcon } from '../../images/menu/scholarship/money-growth.svg';
import { ReactComponent as MotarboardIcon } from '../../images/menu/scholarship/mortarboard.svg';
import { ReactComponent as MotivationIcon } from '../../images/menu/scholarship/motivation.svg';
import { ReactComponent as ToDoIcon } from '../../images/menu/scholarship/to-do-list.svg';
import { ReactComponent as UserIcon } from '../../images/menu/scholarship/user.svg';
import styles from '../../routes/Scholarship/scholarship.module.scss';
import RoleCode from '../../types/Roles/roleCode';
import AcademicYear from '../../types/Scholarships/AcademicYear';
import FollowUpType from '../../types/Scholarships/followUpType';
import { ColorStepElement, ScholarshipStateCode, ScholarshipSteps, SubmenuItemTypeOption } from './definitions';

const createSubmenuItem = (
  componentType: SubmenuItemTypeOption,
  step: number,
  readOnly = false,
  isSemestral = false,
): SubmenuItem => {
  const selected = componentType === step;
  let title = '';
  let iconMobile = <IconScholarship />;
  switch (componentType) {
    case SubmenuItemTypeOption.DATOS_JOVEN:
      title = 'Datos joven';
      iconMobile = <UserIcon />;
      break;
    case SubmenuItemTypeOption.VALORACION_MOTIVACIONAL:
      title = 'Valoración motivacional';
      iconMobile = <MotivationIcon />;
      break;
    case SubmenuItemTypeOption.SITUACION_ACTUAL:
      title = 'Situación actual del/la joven';
      iconMobile = <ToDoIcon />;
      break;
    case SubmenuItemTypeOption.VALORACION_EDUCATIVA:
      title = 'Valoración educativa';
      iconMobile = <ListVerifiedIcon />;
      break;
    case SubmenuItemTypeOption.VALORACION_ECONOMICA:
      title = 'Valoración económica';
      iconMobile = <MoneyIcon />;
      break;
    case SubmenuItemTypeOption.IMPORTE_BECA:
      title = 'Importe de la beca';
      iconMobile = <FinanceIcon />;
      break;
    case SubmenuItemTypeOption.ENTREVISTA:
      title = 'Entrevista';
      iconMobile = <MotarboardIcon />;
      break;
    case SubmenuItemTypeOption.VALORACION_FORM:
      title = 'Valoración';
      iconMobile = <ListVerifiedIcon />;
      break;
    case SubmenuItemTypeOption.APROBACION:
      title = 'Aprobación';
      iconMobile = <AproveIcon />;
      break;
    case SubmenuItemTypeOption.CONFIRMACION:
      title = 'Confirmación';
      iconMobile = <ListIcon />;
      break;
    case SubmenuItemTypeOption.PAGOS:
      title = 'Pagos';
      iconMobile = <FinanceIcon />;
      break;
    case SubmenuItemTypeOption.JUSTIFICACION_PENDIENTE:
      title = 'Justificar importes';
      iconMobile = <ListVerifiedIcon />;
      break;
    case SubmenuItemTypeOption.JUSTIFICACION_ECONOMICA:
      title = tScholarshipKey('justification_menu_title');
      iconMobile = <ListVerifiedIcon />;
      break;
    case SubmenuItemTypeOption.FOLLOW_T1:
      title = isSemestral ? 'Seguimiento S1' : 'Seguimiento T1';
      iconMobile = <FilesIcon />;
      break;
    case SubmenuItemTypeOption.FOLLOW_T2:
      title = isSemestral ? 'Seguimiento S2' : 'Seguimiento T2';
      iconMobile = <FilesIcon />;
      break;
    case SubmenuItemTypeOption.FOLLOW_T3:
      title = 'Seguimiento T3';
      iconMobile = <FilesIcon />;
      break;
  }
  return {
    title: tScholarshipKey(title),
    iconMobile,
    componentId: componentType,
    type: 'component',
    selected,
    component: componentType,
    readOnly,
  };
};

const createSubMenusFollow = (
  stateCode: ScholarshipStateCode,
  step: number,
  isSemestral: boolean,
  t1: boolean,
  t2: boolean,
  t3: boolean,
  isCustomer: boolean,
  readOnly = false,
  economicJustification: boolean,
): SubmenuItem[] => {
  const menus: SubmenuItem[] = [];

  switch (stateCode) {
    case ScholarshipStateCode.FOLLOW_S1:
    case ScholarshipStateCode.FOLLOW_T1:
    case ScholarshipStateCode.ASSIGNED:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, !isCustomer, isSemestral));
      }
      break;
    case ScholarshipStateCode.END_EVALUATION_S1:
    case ScholarshipStateCode.END_EVALUATION_T1:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, true, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, !isCustomer, isSemestral));
      }
      break;
    case ScholarshipStateCode.FOLLOW_S2:
    case ScholarshipStateCode.FOLLOW_T2:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, true, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, !isCustomer, isSemestral));
      }
      break;
    case ScholarshipStateCode.END_EVALUATION_T2:
    case ScholarshipStateCode.END_EVALUATION_S2:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, true, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, true, isSemestral));
      }
      if (t3) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T3, step, !isCustomer, isSemestral));
      }
      break;
    case ScholarshipStateCode.FOLLOW_T3:
    case ScholarshipStateCode.END_EVALUATION_T3:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, true, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, true, isSemestral));
      }
      if (t3) {
        menus.push(
          createSubmenuItem(
            SubmenuItemTypeOption.FOLLOW_T3,
            step,
            stateCode === ScholarshipStateCode.END_EVALUATION_T3,
          ),
        );
      }
      break;
    case ScholarshipStateCode.COMPLETED:
    case ScholarshipStateCode.END_EVALUATION:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, readOnly, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, readOnly, isSemestral));
      }
      if (!isSemestral && t3) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T3, step, readOnly));
      }
      break;
    case ScholarshipStateCode.PENDING_JUSTIFICATION:
    case ScholarshipStateCode.ABANDONMENT:
      if (t1) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T1, step, readOnly, isSemestral));
      }
      if (t2) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T2, step, readOnly, isSemestral));
      }
      if (t3) {
        menus.push(createSubmenuItem(SubmenuItemTypeOption.FOLLOW_T3, step, readOnly));
      }
      break;
  }

  if (economicJustification) {
    const economicJustificationReadOnly =
      stateCode === ScholarshipStateCode.ABANDONMENT ||
      stateCode === ScholarshipStateCode.RENUNCIATION ||
      stateCode === ScholarshipStateCode.COMPLETED;
    menus.push(createSubmenuItem(SubmenuItemTypeOption.JUSTIFICACION_ECONOMICA, step, economicJustificationReadOnly));
  }
  return menus;
};

type GetMenuOptionsParams = {
  stateCode: ScholarshipStateCode;
  steps: ScholarshipSteps;
  roleCode: RoleCode;
  followUpType: FollowUpType;
  academicYear: AcademicYear;
  isInstitut: boolean;
  isNouscims: boolean;
  isConfirmedWithDiff: boolean;
  t1: boolean;
  t2: boolean;
  t3: boolean;
  step: number;
  scholarshipPayment: number;
  isEdit: boolean;
  editInterview: boolean;
  hasPayments: boolean;
};

const getMenuOptions = (options: GetMenuOptionsParams): SubmenuItem[] => {
  const {
    stateCode,
    roleCode,
    followUpType,
    academicYear,
    isInstitut,
    isNouscims,
    isConfirmedWithDiff,
    t1,
    t2,
    t3,
    step,
    isEdit,
    editInterview,
    hasPayments,
  } = options;

  const economicJustification = hasPayments && !isInstitut;
  const menuOptions: SubmenuItem[] = [];
  const readOnlyFollow = !roleCode.isUsuarioBeca() && isNouscims ? !isEdit : true;

  menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.DATOS_JOVEN, step));
  menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_MOTIVACIONAL, step));
  if (!academicYear.isBeyond2023()) {
    menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.SITUACION_ACTUAL, step));
  }
  menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_EDUCATIVA, step));
  menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_ECONOMICA, step));
  menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.IMPORTE_BECA, step));

  switch (stateCode) {
    case ScholarshipStateCode.PENDING_VALUATION:
      if (!roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, !editInterview));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, false));
        if (isConfirmedWithDiff) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
        }
      }
      break;
    case ScholarshipStateCode.VALUATED:
      if (!roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, !editInterview));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, false));
        if (isConfirmedWithDiff) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
        }
      }
      break;
    case ScholarshipStateCode.PENDING_INFO:
      if (!roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, !editInterview));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
        if (isConfirmedWithDiff) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, false));
        }
      }
      break;
    case ScholarshipStateCode.DESTIMATED:
      if (!roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, !editInterview));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
        if (isConfirmedWithDiff) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, false));
        }
      }
      break;
    case ScholarshipStateCode.PENDING_INTERVIEW_VALORATION:
      if (roleCode.isEntrevistador()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, false));
      }
      break;
    case ScholarshipStateCode.ADMIN_REVIEW:
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, !isEdit));
      }
      menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, !isNouscims));
      break;
    case ScholarshipStateCode.APPROVED:
      if (roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, false));
      } else {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, true));

        if (isNouscims) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, !isEdit));
        }
      }
      break;
    case ScholarshipStateCode.ASSIGNED:
      if (roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, true));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      }
      if (isInstitut) {
        menuOptions.push(
          createSubmenuItem(
            SubmenuItemTypeOption.PAGOS,
            step,
            !(roleCode.isAdministracionFNC || roleCode.isSoporteAdministracion),
          ),
        );
      }

      menuOptions.push(
        ...createSubMenusFollow(
          stateCode,
          step,
          followUpType.isBiannual(),
          t1,
          false,
          false,
          roleCode.isUsuarioBeca(),
          readOnlyFollow,
          economicJustification,
        ),
      );

      break;
    case ScholarshipStateCode.CONFIRMATION:
      if (roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, true));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, !isEdit));
      }
      if (isInstitut) {
        menuOptions.push(
          createSubmenuItem(
            SubmenuItemTypeOption.PAGOS,
            step,
            !(roleCode.isAdministracionFNC || roleCode.isSoporteAdministracion),
          ),
        );
      }
      break;
    case ScholarshipStateCode.RENUNCIATION:
    case ScholarshipStateCode.ABANDONMENT:
    case ScholarshipStateCode.CLOSURE:
      if (roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      }
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, true));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      }
      if (isInstitut) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.PAGOS, step, true));
      }
      if (stateCode === ScholarshipStateCode.ABANDONMENT) {
        menuOptions.push(
          ...createSubMenusFollow(
            stateCode,
            step,
            followUpType.isBiannual(),
            t1,
            t2,
            t3,
            roleCode.isUsuarioBeca(),
            true,
            economicJustification,
          ),
        );
      }
      break;
    case ScholarshipStateCode.FOLLOW_S1:
    case ScholarshipStateCode.END_EVALUATION_S1:
    case ScholarshipStateCode.FOLLOW_T1:
    case ScholarshipStateCode.END_EVALUATION_T1:
    case ScholarshipStateCode.FOLLOW_S2:
    case ScholarshipStateCode.END_EVALUATION_S2:
    case ScholarshipStateCode.FOLLOW_T2:
    case ScholarshipStateCode.END_EVALUATION_T2:
    case ScholarshipStateCode.FOLLOW_T3:
    case ScholarshipStateCode.END_EVALUATION_T3:
    case ScholarshipStateCode.END_EVALUATION:
    case ScholarshipStateCode.COMPLETED:
      if (roleCode.isUsuarioBeca()) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      } else {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.ENTREVISTA, step, true));
        if (isNouscims) {
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, true));
          menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
        }
      }
      if (isInstitut) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.PAGOS, step, true));
      }
      menuOptions.push(
        ...createSubMenusFollow(
          stateCode,
          step,
          followUpType.isBiannual(),
          t1,
          t2,
          t3,
          roleCode.isUsuarioBeca(),
          readOnlyFollow,
          economicJustification,
        ),
      );
      break;
    case ScholarshipStateCode.PENDING_JUSTIFICATION:
      if (isNouscims) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.VALORACION_FORM, step, !isEdit));
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.APROBACION, step, !isEdit));
      }
      menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.CONFIRMACION, step, true));
      if (isInstitut) {
        menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.PAGOS, step, true));
      }

      const readOnlyFollowPending = roleCode.isUsuarioBeca() ? true : isNouscims ? !isEdit : false;

      menuOptions.push(
        ...createSubMenusFollow(
          stateCode,
          step,
          followUpType.isBiannual(),
          t1,
          t2,
          t3,
          roleCode.isUsuarioBeca(),
          readOnlyFollowPending,
          economicJustification,
        ),
      );

      menuOptions.push(createSubmenuItem(SubmenuItemTypeOption.JUSTIFICACION_PENDIENTE, step, isNouscims));
      break;
  }

  return menuOptions;
};

const pickColorClass = (color: ColorStepElement) => {
  switch (color) {
    case 'red':
      return styles.redBullet;
    case 'yellow':
      return styles.yellowBullet;
    case 'green':
      return styles.greenBullet;
    default:
      return styles.selectedListItemGrey;
  }
};

const scholarhipCreation = (steps: ScholarshipSteps, step: number): SubmenuItem[] => {
  const {
    personalInformation,
    motivationalInformation,
    currentSituation,
    educationalValoration,
    economicalValoration,
    scholarshipCost,
  } = steps;

  const colors: ColorStepElement[] = [
    personalInformation.color,
    motivationalInformation.color,
    currentSituation.color,
    educationalValoration.color,
    economicalValoration.color,
    scholarshipCost.color,
  ];

  const stepsMenuOptions = getMenuOptions({
    stateCode: ScholarshipStateCode.CREATION,
    roleCode: RoleCode.educador(),
    followUpType: FollowUpType.quaterly(),
    academicYear: AcademicYear.beyond2023(),
    isInstitut: false,
    isNouscims: false,
    isConfirmedWithDiff: false,
    t1: false,
    t2: false,
    t3: false,
    step,
    isEdit: false,
    hasPayments: false,
  } as GetMenuOptionsParams);
  stepsMenuOptions.forEach((ele: SubmenuItem) => {
    let colorStyle;
    if ('componentId' in ele) {
      colorStyle = pickColorClass(colors[ele.componentId]);
      ele.colorStyle = colors[ele.componentId];
    }
    ele.icon = <FiberManualRecordSharpIcon fontSize="small" classes={{ root: colorStyle }} />;
  });
  return stepsMenuOptions;
};

export const menuOptionsByState = (menuOption: GetMenuOptionsParams): SubmenuItem[] => {
  if (menuOption.stateCode === ScholarshipStateCode.CREATION && menuOption.roleCode.isUsuarioBeca()) {
    return scholarhipCreation(menuOption.steps, menuOption.step);
  }
  return getMenuOptions(menuOption);
};
