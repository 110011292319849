import { SubareaInternalProjectType } from '../../redux/subareaInternalProjectType/subareaInternalProjectType';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export interface SubareaInternalProjectTypeResponse {
  data: SubareaInternalProjectType[];
}

export const getSubareaInternalProjectType = async (): Promise<SubareaInternalProjectTypeResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return (await apiCall)<SubareaInternalProjectTypeResponse>(`${baseURL}/internal_project/type`, init);
};
