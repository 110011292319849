import { InputLabel, MenuItem } from '@material-ui/core';
import React from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import assertUnreachable from '../../helpers/assertUnreachable';
import { tFormKey } from '../../helpers/translate';
import { validateFields } from '../../helpers/validator';
import { showSnackbar } from '../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../redux/FeedbackAPI/definitions';
import { CodeSectionProject, CustomFieldDataTypes, SizeConfiguratorData } from '../../redux/kpis/definitions';
import { FormProjectFields, ProjectData } from '../../redux/project/definitions';
import ClipBoard from '../ClipBoard/ClipBoard';
import KeyboardDatePickerController from '../DatePicker/KeyboardDatePickerController';
import TextInputController from '../Inputs/TextInputController/TextInputController';
import SelectController from '../Select/SelectController';
import SubTitle from '../SubTitle/Subtitle';
import FncAddDocuments, { AddDocumentsProps } from '../UploadFile/FncAddDocuments';
import styles from './genericForm.module.scss';

type GenericForm<T extends FieldValues> = {
  fields: { documents: FormProjectFields[]; elements: FormProjectFields[] } | null;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  errors: FieldErrors<T>;
  project: ProjectData;
  section: CodeSectionProject;
  disabled?: boolean;
  showSnackbar: (message: string, severity: SeveritySnackbar) => void;
} & Pick<AddDocumentsProps, 'actions' | 'upload' | 'download' | 'remove' | 'getFile' | 'addDoc'>;

const GenericForm = <T extends FieldValues>({
  fields,
  schema,
  control,
  errors,
  project,
  actions,
  section,
  disabled,
  download,
  upload,
  remove,
  getFile,
  addDoc,
  showSnackbar,
}: GenericForm<T>) => {
  if (!fields || !fields.elements) return null;

  const { documents, elements } = fields;

  const sortedFields = elements.sort((a, b) => (a.position < b.position ? -1 : 1));
  const sortedDocuments = documents && documents.sort((a, b) => (a.position < b.position ? -1 : 1));

  const getArrayDocs = (sortedDocuments: FormProjectFields[], nameT: string) => {
    const arrDocs = sortedDocuments
      .filter(e => e.name === nameT)
      .map(f => {
        return f.value_file;
      });

    return arrDocs[0];
  };

  const getMaxCharacters = (size: SizeConfiguratorData) => {
    switch (size) {
      case 'Small':
        return 34;
      case 'Medium':
        return 75;
      case 'Large':
        return 162;
    }
    assertUnreachable(size);
  };

  return (
    <div className={styles.container}>
      {sortedFields.map(({ field_data_types, label, name, id, size, value, ...props }) => {
        switch (field_data_types) {
          case CustomFieldDataTypes.TEXTO:
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <TextInputController
                  name={name}
                  validator={validateFields(project, name, section)}
                  schema={schema}
                  disabled={disabled}
                  control={control}
                  errors={errors}
                  fullWidth
                  defaultValue={value}
                  InputProps={{
                    inputProps: { maxLength: getMaxCharacters(size) },
                  }}
                />
              </div>
            );
          case CustomFieldDataTypes.BLOQUE_DE_TEXTO:
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <TextInputController
                  name={name}
                  validator={validateFields(project, name, section)}
                  schema={schema}
                  disabled={disabled}
                  control={control}
                  errors={errors}
                  rowsMax={3}
                  rows={3}
                  multiline
                  fullWidth
                  defaultValue={value}
                  InputProps={{
                    inputProps: { maxLength: 2000 },
                  }}
                />
              </div>
            );
          case CustomFieldDataTypes.LISTA_DE_VALORES:
            if (!props.options || props.options.length === 0) return null;
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <SelectController
                  name={name}
                  schema={schema}
                  control={control}
                  validator={validateFields(project, name, section)}
                  errors={errors}
                  fullWidth
                  disabled={disabled}
                  defaultValue={value?.toString()}
                >
                  {props.options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </SelectController>
              </div>
            );
          case CustomFieldDataTypes.FECHA:
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <KeyboardDatePickerController
                  key={id}
                  name={name}
                  schema={schema}
                  control={control}
                  disabled={disabled}
                  errors={errors}
                  defaultValue={value || ''}
                  fullWidth
                />
              </div>
            );
          case CustomFieldDataTypes.SUBTITULO:
            return <SubTitle>{value || ''}</SubTitle>;
          case CustomFieldDataTypes.ETIQUETA:
            return <InputLabel className={styles.formLabel}>{label || ''}</InputLabel>;
          case CustomFieldDataTypes.LINK:
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <TextInputController
                  name={name}
                  validator={validateFields(project, name, section)}
                  schema={schema}
                  disabled={disabled}
                  control={control}
                  errors={errors}
                  fullWidth
                  defaultValue={value}
                  InputProps={{
                    inputProps: { maxLength: getMaxCharacters(size) },
                    endAdornment: <ClipBoard disabled={disabled} value={value || ''} showSnackbar={showSnackbar} />,
                  }}
                />
              </div>
            );
          case CustomFieldDataTypes.NUMERICO:
            return (
              <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
                <InputLabel className={styles.label}>{label}</InputLabel>
                <TextInputController
                  name={name}
                  schema={schema}
                  control={control}
                  validator={validateFields(project, name, section)}
                  errors={errors}
                  fullWidth
                  disabled={disabled}
                  defaultValue={value}
                  type="number"
                />
              </div>
            );
          default:
            return null;
        }
        // assertUnreachable(field_data_types);
      })}
      {sortedDocuments &&
        sortedDocuments.map(({ label, name, id, size }) => {
          return (
            <div className={`${styles.input} ${styles[`size_${size}`]}`} key={id}>
              <InputLabel className={styles.label}>{label}</InputLabel>
              <FncAddDocuments
                documents={getArrayDocs(sortedDocuments, name)}
                genericId={project.id}
                actions={actions}
                title={tFormKey('Documentos')}
                addDoc={addDoc}
                upload={upload}
                getFile={getFile}
                download={download}
                remove={remove}
                originalName
                disabled={disabled}
                addRow
                multidocument
                fieldName={name}
              />
            </div>
          );
        })}
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  showSnackbar: (message: string, severity: SeveritySnackbar): void => dispatch(showSnackbar(message, severity)),
});

export default connect(null, mapDispatchToProps)(GenericForm);
