import { CostCenter, CostCenterCode } from '../../routes/Accounting/CostCenter/SwitchCostCenter';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

const getCode = (section: CostCenterCode) => {
  switch (section) {
    case 'canal':
      return 'channel';
    case 'delegacion':
      return 'delegation';
    case 'departamento':
      return 'area';
    case 'proyecto':
      return 'project';
  }
};

export const getCenterCostListByCodeApi = async (section: CostCenterCode): Promise<{ data: CostCenter[] }> => {
  const code = getCode(section);

  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: CostCenter[] }>(`${baseURL}/center_cost/list/${code}`, init);
};

export const findElementOfCenterCostApi = async (
  id: number,
  section: CostCenterCode,
): Promise<{ data: CostCenter }> => {
  const code = getCode(section);

  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: CostCenter }>(`${baseURL}/center_cost/find/${id}/${code}`, init);
};

export const createElementCenterCostApi = async (
  section: CostCenterCode,
  data: CostCenter,
): Promise<{ data: CostCenter }> => {
  const code = getCode(section);

  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return apiCall<{ data: CostCenter }>(`${baseURL}/center_cost/create/${code}`, init);
};

export const deleteElementCenterCostApi = async (
  id: number,
  section: CostCenterCode,
): Promise<{ data: CostCenter[] }> => {
  const code = getCode(section);

  const init = {
    method: 'POST',
    auth: true,
  };

  return apiCall<{ data: CostCenter[] }>(`${baseURL}/center_cost/delete/${id}/${code}`, init);
};

export const editElementCenterCostApi = async (
  id: number,
  section: CostCenterCode,
  data: CostCenter,
): Promise<{ data: CostCenter }> => {
  const code = getCode(section);

  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return apiCall<{ data: CostCenter }>(`${baseURL}/center_cost/edit/${id}/${code}`, init);
};
