import { apiCallNoResponse } from '../../api';
import { Educator } from '../../../redux/internal-projects/educators/Educator';

const baseURL = process.env.REACT_APP_API_URL;

export const postEducator = async (data: Educator): Promise<void> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCallNoResponse(`${baseURL}/internal_project/${data.internal_project_id}/participants/educators`, init);
};
