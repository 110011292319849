import { APIResponse, ZipCodeResponse } from '../../redux/common/definitions';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export const findZipCode = async (code: string): Promise<ZipCodeResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/zip_code/find/${code}`, init);
  return data;
};
