import { NewMessage } from '../redux/notification/actions';
import { MessageThread, NotificationsRead } from '../redux/notification/definitions';
import { apiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

export const markNotificationsAsRead = async (notifications: NotificationsRead): Promise<string> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(notifications),
  };

  return apiCall<string>(`${baseURL}/user/notifications/update`, init);
};

export const getNotificationsUpdate = async (version: number): Promise<string> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ data: { version } }),
  };
  return apiCall<string>(`${baseURL}/user/notifications/updates`, init);
};

export const newMessage = async (new_message: NewMessage): Promise<{ data: MessageThread }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(new_message),
  };
  return apiCall<{ data: MessageThread }>(`${baseURL}/user/notifications/message`, init);
};

export const removePendingTaskFromApi = async (notification_id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  const { data } = await apiCall<{ data: string }>(`${baseURL}/notifications/${notification_id}`, init);
  return data === 'OK';
};
