import { APIResponse, TaxRetention } from '../../redux/common/definitions';
import { OperationKey, TransactionCode } from '../../redux/metadata/definitions';
import { apiCall, APIValidationError, Result, validatedApiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export const getTaxRetentions = async (): Promise<TaxRetention[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/tax_retention`, init);
  return data;
};

export const getOneTaxRetention = async (id: number): Promise<TaxRetention> => {
  if (id === 0) {
    return { id: 0, code: '', name: '', percentage: 0.0, payment_account: null } as TaxRetention;
  } else {
    const init = {
      method: 'GET',
      auth: true,
    };
    const { data } = await apiCall<APIResponse>(`${baseURL}/tax_retention/${id}`, init);
    return data;
  }
};

export const updateTaxRetentions = async (TaxData: TaxRetention): Promise<boolean> => {
  const init = {
    method: 'PUT',
    body: JSON.stringify(TaxData),
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/tax_retention/${TaxData.id}`, init);
  return data === 'OK';
};

export const createTaxRetentions = async (
  TaxData: TaxRetention,
): Promise<Result<{ data: TaxRetention }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(TaxData),
    auth: true,
  };
  return await validatedApiCall<{ data: TaxRetention }>(`${baseURL}/tax_retention/${TaxData.id}`, init);
};

export const deleteOneScholarship = async (id: number) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/tax_retention/${id}`, init);
  return data === 'OK';
};

export const getTransactionCodes = async (): Promise<TransactionCode[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/transaction_code`, init);
  return data;
};

export const getOneTransactionCode = async (id: number): Promise<TransactionCode> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/transaction_code/${id}`, init);
  return data;
};

export const createTransactionCode = async (
  data: TransactionCode,
): Promise<Result<{ data: TransactionCode }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return await validatedApiCall<{ data: TransactionCode }>(`${baseURL}/transaction_code`, init);
};

export const updateTransactionCode = async (itemTransactionCode: TransactionCode): Promise<boolean> => {
  const { id } = itemTransactionCode;
  const init = {
    method: 'PUT',
    body: JSON.stringify(itemTransactionCode),
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/transaction_code/${id}`, init);
  return data === 'OK';
};

export const deleteOneTransactionCode = async (id: number) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/transaction_code/${id}`, init);
  return data === 'OK';
};

export const getOperationKeys = async (): Promise<OperationKey[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/operation_key`, init);
  return data;
};

export const getOneOperationKey = async (id: number): Promise<OperationKey> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/operation_key/${id}`, init);
  return data;
};

export const createOperationKey = async (
  data: OperationKey,
): Promise<Result<{ data: OperationKey }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  return await validatedApiCall<{ data: OperationKey }>(`${baseURL}/operation_key`, init);
};

export const updateOperationKey = async (itemOperationKey: OperationKey): Promise<boolean> => {
  const { id } = itemOperationKey;
  const init = {
    method: 'PUT',
    body: JSON.stringify(itemOperationKey),
    auth: true,
  };
  const { data } = await apiCall<APIResponse>(`${baseURL}/operation_key/${id}`, init);
  return data === 'OK';
};

export const deleteOneOperationKey = async (id: number) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<APIResponse>(`${baseURL}/operation_key/${id}`, init);
  return data === 'OK';
};
