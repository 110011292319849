import { generateUuid } from '../helpers/uuidGenerator';
import { FileItem } from '../redux/common/definitions';
import { apiCall, apiCallBlob, apiCallSuccess } from './api';

const baseURL = process.env.REACT_APP_API_URL;

export const uploadFileFromApi = async (file: File): Promise<FileItem> => {
  const dataForm = new FormData();
  dataForm.append('document', file, file.name || generateUuid());
  const init = {
    method: 'POST',
    auth: true,
    body: dataForm,
    defaultContentType: true,
  };
  const { data } = await apiCall<{ data: FileItem }>(`${baseURL}/file`, init);
  return data;
};

export const downloadFileFromApi = async (file_id: number): Promise<Blob> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCallBlob(`${baseURL}/file/downloadOriginal/${file_id}`, init);
};

export const deleteFileFromApi = async (file_id: number): Promise<boolean> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  return apiCallSuccess(`${baseURL}/file/${file_id}`, init);
};
