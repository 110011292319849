import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

interface RolesProps {
  id: number;
  name: string;
}

interface SelectResult {
  data: RolesProps[];
}
export const rolesData = async (entidad: boolean): Promise<SelectResult> => {
  const init = {
    method: 'GET',
  };

  const route = entidad ? 'create_entity' : 'create_user';

  return await apiCall<SelectResult>(`${baseURL}/role/route/${route}`, init);
};
