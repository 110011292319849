import { apiCallData } from '../api';
import { AllowedStates } from '../../redux/scholarship/changeState/definitions';
import { ScholarshipData } from '../../redux/scholarship/definitions';

const baseURL = process.env.REACT_APP_API_URL;

export const getScholarshipAllowedStatesFromApi = async (scholarshipId: number): Promise<AllowedStates> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCallData<AllowedStates>(`${baseURL}/scholarships/${scholarshipId}/allowed_states`, init);
};

export const scholarshipChangeStateFromApi = async (
  id: number,
  current_state: string,
  next_state: string,
): Promise<ScholarshipData> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ id, current_state, next_state }),
  };
  return apiCallData<ScholarshipData>(`${baseURL}/scholarship/change_state`, init);
};
