import { InternalProjectId, EducatorId } from '../../../redux/internal-projects/educators/Educator';
import { apiCall } from '../../api';

const baseURL = process.env.REACT_APP_API_URL;

export const deleteEducator = async (id: InternalProjectId, educatorId: EducatorId): Promise<void> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<void>(`${baseURL}/internal_project/${id}/participants/educator/${educatorId}`, init);
};
