import { APIValidationError, Result, validatedApiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

interface ResetPasswordProps {
  email: string;
}

interface ResetResult {
  data: ResetPasswordProps[];
}

interface PasswordObj {
  password: string;
  password2: string;
}

export const ResetPassword = async (
  token: string,
  passwordObj: PasswordObj,
): Promise<Result<ResetResult, APIValidationError>> => {
  const data = {
    password: passwordObj.password,
    password2: passwordObj.password2,
    token,
  };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return await validatedApiCall<ResetResult>(`${baseURL}/user/reset-password/`, init);
};
