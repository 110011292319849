import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as yup from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInput from '../../../../components/Inputs/TextInput';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import FncAddDocuments from '../../../../components/UploadFile/FncAddDocuments';
import { tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem } from '../../../../redux/common/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { scholarshipSetData } from '../../../../redux/scholarship/actions';
import { JustificationDetails, ScholarshipData } from '../../../../redux/scholarship/definitions';
import {
  selectGetScholarshipDocumentationPerCode,
  selectTotalConfirmation,
  selectTotalJustified,
} from '../../../../redux/scholarship/selectors';
import { validateFollowupJustification } from '../../../../validations/validatorScholarship';
import styles from './pendingJustification.module.scss';
import MessageInfo from '../../../../components/MessageInfo/MessageInfo';

interface PendingJustificationProps {
  readOnly: boolean;
  scholarshipData: ScholarshipData;
  details_total_justify: JustificationDetails;
  total_confirmation: number;
  documentations: DocumentationItem[];
  setData: (key: string, value: string | number) => void;
  getFile: (documentation_id: number, name: string, scholarship_id: number) => void;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  uploadDocument: (documentation_id: number, data: File, scholarshipId: number) => void;
  downloadDocument: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument: (documentation_id: number, scholarship_id: number) => void;
}

const PendingJustification: React.FC<PendingJustificationProps> = ({
  readOnly,
  scholarshipData,
  details_total_justify,
  total_confirmation,
  documentations,
  setData,
  getFile,
  addDoc,
  uploadDocument,
  downloadDocument,
  deleteDocument,
}) => {
  const {
    adjustment_amount,
    mec_confirm,
    total_paid,
    enrollment_justify,
    monthly_price_justify,
    materials_justify,
    transport_price_justify,
    other_costs_justify,
    young_contrib_justify,
    mec_justify,
    adjustment_amount_justify,
  } = scholarshipData;

  const pendingJustificationSchema = yup.object().shape({});
  const { control, errors, setError, clearError } = useForm<ScholarshipData>({
    mode: 'onSubmit',
    submitFocusError: true,
  });

  const totalJustified =
    Number(details_total_justify.enrollment_total_justify) +
    Number(details_total_justify.monthly_price_total_justify) +
    Number(details_total_justify.materials_total_justify) +
    Number(details_total_justify.transport_price_total_justify) +
    Number(details_total_justify.other_costs_total_justify) +
    Number(details_total_justify.adjustment_amount_total_justify);
  const totalToJustify =
    Number(enrollment_justify) +
    Number(monthly_price_justify) +
    Number(materials_justify) +
    Number(transport_price_justify) +
    Number(other_costs_justify) +
    Number(adjustment_amount_justify);

  const studentContributionConfirmed = Number(scholarshipData.young_contrib_approval) + Number(mec_confirm);
  const totalScholarshipConfirmed =
    Number(total_confirmation) + Number(adjustment_amount) - studentContributionConfirmed;

  const studentContributionJustified =
    Number(details_total_justify.student_contribution_total_justify) + Number(details_total_justify.mec_total_justify);
  const studentContributionToJustify = Number(young_contrib_justify) + Number(mec_justify);

  const totalScholarshipJustified = totalJustified - studentContributionJustified;
  const totalScholarshipToJustify = totalToJustify - studentContributionToJustify;
  const totalScholarship = totalScholarshipJustified + totalScholarshipToJustify;
  let totalRemaining = Number(total_paid) - totalScholarship;
  if (totalRemaining < 0) {
    totalRemaining = 0.0;
  }

  const actions = ['download', 'view'];

  const handleJustificationBlur = (
    confirmedValue: number,
    justifiedValue: number,
    totalJustifiedValue: number,
    fieldName: string,
  ) => {
    validateFollowupJustification(confirmedValue, justifiedValue, totalJustifiedValue, fieldName, setError, clearError);
    const errorKeys = Object.keys(errors);
    const index = errorKeys.findIndex((k: string) => k === fieldName);
    if (index === -1) {
      setData(fieldName, justifiedValue);
    }
  };

  return (
    <FormContainer title={tScholarshipKey('Justificar importes')}>
      <FormContainer title="" className={styles.justificationSection}>
        <MessageInfo>{tScholarshipKey('justification_deprecated_message')}</MessageInfo>
        <div className={styles.container}>
          <div className={styles.title_textInput}>{tScholarshipKey('Confirmado')}</div>
          <div className={styles.title_textInput}>{tScholarshipKey('Justificado')}</div>
          <div className={styles.title_textInput}>{tScholarshipKey('Justificar')}</div>
          <div className={styles.title_textInput}>{tScholarshipKey('total-justificado')}</div>

          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Matriculación confirmada')}
            value={scholarshipData.enrollment_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('matriculacion-total-justificada')}
            value={details_total_justify.enrollment_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Matriculación')}
            name="enrollment_justify"
            price
            defaultValue={enrollment_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.enrollment_confirm,
                +e.target.value,
                details_total_justify.enrollment_total_justify || 0,
                'enrollment_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Matriculación total')}
            value={
              (Number(details_total_justify.enrollment_total_justify) + Number(enrollment_justify)).toFixed(2) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Importe o mensualidad confirmada')}
            value={scholarshipData.monthly_price_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('mensualidad-total-justificada')}
            value={details_total_justify.monthly_price_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Importe o Mensualidad')}
            name="monthly_price_justify"
            price
            defaultValue={monthly_price_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.monthly_price_confirm,
                +e.target.value,
                details_total_justify.monthly_price_total_justify || 0,
                'monthly_price_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Importe o Mensualidad total')}
            value={
              (Number(details_total_justify.monthly_price_total_justify) + Number(monthly_price_justify)).toFixed(2) ||
              '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Materiales confirmados')}
            value={scholarshipData.materials_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('materiales-totales-justificados')}
            value={details_total_justify.materials_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Materiales')}
            name="materials_justify"
            price
            defaultValue={materials_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.materials_confirm,
                +e.target.value,
                details_total_justify.materials_total_justify || 0,
                'materials_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Materiales totales')}
            value={
              (Number(details_total_justify.materials_total_justify) + Number(materials_justify)).toFixed(2) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Transporte confirmado')}
            value={scholarshipData.transport_price_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('transporte-total-justificado')}
            value={details_total_justify.transport_price_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Transporte')}
            name="transport_price_justify"
            price
            defaultValue={transport_price_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.transport_price_confirm,
                +e.target.value,
                details_total_justify.transport_price_total_justify || 0,
                'transport_price_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Transporte total')}
            value={
              (Number(details_total_justify.transport_price_total_justify) + Number(transport_price_justify)).toFixed(
                2,
              ) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Otros importes confirmados')}
            value={scholarshipData.other_costs_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('otros-importes-totales-justificados')}
            value={details_total_justify.other_costs_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Otros importes')}
            name="other_costs_justify"
            price
            defaultValue={other_costs_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.other_costs_confirm,
                +e.target.value,
                details_total_justify.other_costs_total_justify || 0,
                'other_costs_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Otros importes totales')}
            value={
              (Number(details_total_justify.other_costs_total_justify) + Number(other_costs_justify)).toFixed(2) ||
              '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('total-confirmado')}
            className={styles.highlighted}
            value={total_confirmation.toFixed(2)}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('total-justificado')}
            className={styles.highlighted}
            defaultValue={totalJustified.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('total-justificado')}
            className={styles.highlighted}
            value={totalToJustify.toFixed(2)}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total')}
            className={styles.highlighted}
            value={(totalJustified + totalToJustify).toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
      </FormContainer>
      <FormContainer title="" className={styles.justificationSection}>
        <div className={styles.container}>
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Ajustes de pago')}
            value={adjustment_amount}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Ajustes de pagos justificados')}
            value={details_total_justify.adjustment_amount_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled={readOnly}
            errors={errors}
            label={tScholarshipKey('Justificar ajustes de pago')}
            name="adjustment_amount_justify"
            price
            defaultValue={adjustment_amount_justify}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              handleJustificationBlur(
                +scholarshipData.adjustment_amount,
                +e.target.value,
                details_total_justify.adjustment_amount_total_justify || 0,
                'adjustment_amount_justify',
              );
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Ajustes de pago totales')}
            value={
              (
                Number(details_total_justify.adjustment_amount_total_justify) + Number(adjustment_amount_justify)
              ).toFixed(2) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
      </FormContainer>
      <FormContainer title="" className={styles.justificationSection}>
        <div className={styles.container}>
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Aportación por parte del/la joven')}
            value={scholarshipData.young_contrib_approval}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Aportación joven justificada')}
            value={details_total_justify.student_contribution_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled
            errors={errors}
            label={tScholarshipKey('Aportado joven')}
            name="young_contrib_justify"
            price
            defaultValue={young_contrib_justify || '0.00'}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Aportación total joven')}
            value={
              Number(details_total_justify.student_contribution_total_justify) + Number(young_contrib_justify) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={`${tScholarshipKey('Importe beca MEC')}`}
            value={mec_confirm}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Importe beca MEC justificado')}
            value={details_total_justify.mec_total_justify || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInputController
            control={control}
            schema={pendingJustificationSchema}
            type="number"
            disabled
            errors={errors}
            label={tScholarshipKey('Aportado beca MEC')}
            name="mec_justify"
            price
            defaultValue={mec_justify || '0.00'}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Importe total beca MEC')}
            value={Number(details_total_justify.mec_total_justify) + Number(mec_justify) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            className={styles.containerContrib}
            label={tScholarshipKey('Total aportado justificado')}
            value={
              Number(details_total_justify.student_contribution_total_justify) +
                Number(details_total_justify.mec_total_justify) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total aportado justificado')}
            value={Number(young_contrib_justify) + Number(mec_justify) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total aportado')}
            value={(studentContributionJustified + studentContributionToJustify).toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Pendiente aportación joven')}
            className={styles.gapUntilLastColumn}
            value={
              (
                Number(scholarshipData.young_contrib_approval) +
                Number(mec_confirm) -
                (studentContributionJustified + studentContributionToJustify)
              ).toFixed(2) || '0.00'
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total beca')}
            className={styles.highlighted}
            name="total_scholarship"
            value={totalScholarshipConfirmed.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total justificado - aportación')}
            className={styles.highlighted}
            name="total_justify_scholarship"
            value={totalScholarshipJustified.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('justificado-aportado')}
            className={styles.highlighted}
            value={totalScholarshipToJustify.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('Total justificado beca')}
            className={styles.highlighted}
            value={totalScholarship.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
      </FormContainer>

      <div className={styles.topBorder}></div>
      <FormContainer title="" className={styles.justificationSection}>
        <div className={`${styles.container} ${styles.containerFollowup}`}>
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('TOTAL BECA')}
            className={styles.highlighted}
            value={totalScholarshipConfirmed.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('TOTAL PAGADO')}
            className={styles.highlighted}
            value={total_paid || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('TOTAL JUSTIFICADO BECA')}
            className={styles.highlighted}
            value={totalScholarship.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
          <TextInput
            type="number"
            disabled
            label={tScholarshipKey('REMANENTE')}
            className={styles.highlighted}
            value={totalRemaining.toFixed(2) || '0.00'}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </div>
      </FormContainer>

      {documentations && actions && (
        <FncAddDocuments
          documents={documentations}
          getFile={getFile}
          actions={actions}
          upload={uploadDocument}
          genericId={scholarshipData.id}
          download={downloadDocument}
          multidocument
          remove={deleteDocument}
          addDoc={addDoc}
          disabled={readOnly}
        />
      )}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  details_total_justify: selectTotalJustified(state),
  total_confirmation: selectTotalConfirmation(state),
  documentations: selectGetScholarshipDocumentationPerCode(state, ['MEN', 'MAT', 'MTE', 'TRA']),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number): void => dispatch(scholarshipSetData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingJustification);
