import { apiCall } from '../api';
import { FamilyIncome } from '../../redux/scholarship/familyIncomes/FamilyIncome';

const baseURL = process.env.REACT_APP_API_URL;

export interface FamilyIncomesResponse {
  data: FamilyIncome[];
}

export const getFamilyIncomes = async (scholarshipId: number): Promise<FamilyIncome[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<FamilyIncomesResponse>(
    `${baseURL}/scholarships/${scholarshipId}/family-incomes`,
    init,
  );
  return data;
};

export const createFamilyIncomes = async (familyIncome: FamilyIncome) => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(familyIncome),
  };
  await apiCall<FamilyIncomesResponse>(`${baseURL}/scholarships/${familyIncome.scholarship_id}/family-incomes`, init);
};

export const deleteFamilyIncomes = async (familyIncome: FamilyIncome) => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  await apiCall<FamilyIncomesResponse>(
    `${baseURL}/scholarships/${familyIncome.scholarship_id}/family-incomes/${familyIncome.id}`,
    init,
  );
};

export const updateFamilyIncomes = async (familyIncome: FamilyIncome) => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(familyIncome),
  };
  await apiCall<FamilyIncomesResponse>(
    `${baseURL}/scholarships/${familyIncome.scholarship_id}/family-incomes/${familyIncome.id}`,
    init,
  );
};
