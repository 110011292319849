import { permissionType } from '../constants/permissions_definitions';
import { apiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

interface GetUserPermissionsProps {
  data: {
    permissions: permissionType[];
  };
}
export const getUserPermissions = async (): Promise<GetUserPermissionsProps> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<GetUserPermissionsProps>(`${baseURL}/user/permissions`, init);
};
