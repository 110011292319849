import { Button, ButtonProps, MenuItem } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import Send from '@material-ui/icons/Send';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import {
  cancelEntityAgreementFromApi,
  changeEntityRemmant,
  checkScholarshipAdvancePayments,
  entityProviderInfoChangeFromApi,
  getAgreementByYear,
  getEntityScholarshipPaymentLogsFromApi,
  getScholarshipYearsAgreements,
  makeScholarshipAdvancePayments,
  payAdminManagementFromApi,
  setAgreementByYear,
} from '../../../../api/Entity/entity';
import { APIValidationError } from '../../../../api/api';
import CheckboxCompo from '../../../../components/Checkbox/CheckboxCompo';
import KeyboardDatePickerController from '../../../../components/DatePicker/KeyboardDatePickerController';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import Loading from '../../../../components/Loading/Loading';
import SelectController from '../../../../components/Select/SelectController';
import Title from '../../../../components/Title/Title';
import { DATE_FORMAT } from '../../../../constants/date';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import errorMessage from '../../../../helpers/errorMessage';
import { getDocs } from '../../../../helpers/getDocs';
import { tFormKey, tKey } from '../../../../helpers/translate';
import { showSnackbar } from '../../../../redux/FeedbackAPI/actions';
import { SeveritySnackbar } from '../../../../redux/FeedbackAPI/definitions';
import { selectIsNousCims } from '../../../../redux/auth/selectors';
import { CodeNamePair, DocumentationItem } from '../../../../redux/common/definitions';
import { entitySetData, makeProvider } from '../../../../redux/entity/actions';
import { EntityInfo, TaxRetentionCodes } from '../../../../redux/entity/definitions';
import { selectSendingSage, selectValidationErrors } from '../../../../redux/entity/selectors';
import { getAccountingMetadata } from '../../../../redux/metadata/actions';
import { GenericMetadata, TransactionCode } from '../../../../redux/metadata/definitions';
import {
  selectAcademicYears,
  selectCurrentAcademicYear,
  selectGenericMetadata,
  selectPaymentTypeCodes,
  selectTransactionCodes,
} from '../../../../redux/metadata/selectors';
import { selectUserCan } from '../../../../redux/permissions/selectors';
import { PaymentInfo } from '../../../../redux/project/definitions';
import { AppState } from '../../../../redux/root-reducer';
import { ScholarshipAgreementCode } from '../../../../redux/scholarship/definitions';
import { AccountingDataSchema } from '../../../../validations/formSchema';
import PaymentSettingsForm from '../../../Project/FormProject/PaymentSettingsForm/PaymentSettingsForm';
import styles from '../../entityData.module.scss';
import UploadDocuments from './UploadDocuments';
import accountStyles from './accountingData.module.scss';
import SignatureDocuments from '../../../../components/SignatureDocuments/SignatureDocuments';

interface InputsData {
  expense_account: string | null;
  provider_code: string | null;
  payable_account: string | null;
  supplier_code: null | string;
  supplier_payable_account: null | string;
  retention_code: null | string;
  transaction_code: null | string;
  needs_347: number;
  center_cost_channel_code: string | null;
  center_cost_delegation_code: string | null;
  center_cost_area_code: string | null;
  center_cost_project_code: string | null;
  agreement_number: string | null;
  concept: string | null;
  description: string | null;
  course: string;
  advance_payment: number | null;
  advance_payment_date: moment.Moment | null;
  advance_payment_remainder: number | null;
  total_confirm_amount: number;
  total_justified_amount: number;
  total_paid_amount: number;
  total_rejected_and_dropped: number;
  admin_management_amount: number;
  admin_management_date: moment.Moment | null;
  admin_management_sended: boolean;
}

interface AccountingDataProps {
  entity: EntityInfo;
  buttons?: boolean;
  academicYears: string[];
  currentAcademicYear: string;
  leftButtons?: boolean;
  disabled?: boolean;
  documentation: DocumentationItem[];
  buttonActionSave: ButtonProps;
  buttonSageProvider: ButtonProps;
  buttonAdvancedImport: ButtonProps;
  buttonModifyAccounts: ButtonProps;
  buttonCancelAgreement: ButtonProps;
  isPma: boolean;
  isCdn: boolean;
  validationErrors: APIValidationError | null;
  genericMetadata: GenericMetadata;
  transactionCodesMetadata: TransactionCode[];
  paymentTypeCodesMetadata: CodeNamePair[];
  sendingSage: boolean;
  allowTransactionCodes: boolean;
  allowEntityManualRemnant: boolean;
  isNouCims: boolean;
  onSave: (data: any) => void;
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number) => void;
  makeProvider: (id: number) => void;
  setData: (key: string, value: string | number) => void;
  upload: (document_id: number, data: File, entity_id: any, force_add?: boolean | false) => void;
  download: (file_id: number, name: string, entityId: number) => void;
  remove: (file_id: number, entityId: number) => void;
  getAccountingMetadata: () => void;
}

const RemmantModal = React.lazy(() => import('./RemmantModal'));
const PaymentModal = React.lazy(() => import('../../../Project/Modal/PaymentModal/PaymentModal'));

const AccountingData: React.FC<AccountingDataProps> = ({
  entity,
  buttons,
  disabled,
  academicYears,
  currentAcademicYear,
  isPma,
  isCdn,
  validationErrors,
  genericMetadata,
  transactionCodesMetadata,
  paymentTypeCodesMetadata,
  sendingSage,
  buttonActionSave,
  buttonSageProvider,
  buttonModifyAccounts,
  allowTransactionCodes,
  buttonAdvancedImport,
  buttonCancelAgreement,
  isNouCims,
  allowEntityManualRemnant,
  makeProvider,
  showSnackbar,
  onSave,
  setData,
  getAccountingMetadata,
}) => {
  const [payModal, setPayModal] = useState({ view: false, modal: {} as PaymentInfo });
  const [remmantModal, setRemmantModal] = useState<boolean>(false);
  const {
    expense_account,
    provider_code,
    payable_account,
    supplier_code,
    supplier_payable_account,
    retention_code,
    transaction_code,
    needs_347,
    has_scholarship,
    legal_person,
    natural_person,
    agreement_state,
    joined_49_2020,
    payment_type_code,
  } = entity;
  const [agreementDocumentation, setAgreementDocumentation] = useState<DocumentationItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [entityAgreementId, setEntityAgreementId] = useState(0);
  const [allowSetAccount, setAllowSetAccount] = useState(false);
  const [inputs, setInputs] = useState({} as InputsData);
  const [, setDates] = useState<string[]>([]);
  const [datePicker, setDatePicker] = useState({ advancePayment: false, admin_management: false });
  const [selectedRetentionCode, setSelectedRetentionCode] = useState(retention_code);
  const [academicYear, setAcademicYear] = useState<string>(currentAcademicYear);

  const { handleSubmit, errors, control, reset, watch, setValue, setError, register } = useForm<InputsData>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: AccountingDataSchema,
  });

  const asingDateValue = (date: string | null) => {
    return date ? moment(date, DATE_FORMAT) : null;
  };

  const getDateFormatted = (date: moment.Moment | null) => {
    if (date && date.isValid()) {
      return date.format(DATE_FORMAT);
    }
    return null;
  };

  const fetchGetPayment = async () => {
    try {
      const dates = await getScholarshipYearsAgreements(entity.id);
      setDates(dates);
      if (!dates.length || !academicYear) return;
      const {
        id,
        agreement_number,
        documentations,
        agreement_state,
        center_cost_channel_code,
        center_cost_delegation_code,
        center_cost_area_code,
        center_cost_project_code,
        description,
        concept,
        advance_payment,
        advance_payment_date,
        advance_payment_remainder,
        total_confirm_amount,
        total_justified_amount,
        total_paid_amount,
        total_rejected_and_dropped,
        admin_management_amount,
        admin_management_date,
        admin_management_sended,
      } = await getAgreementByYear(entity.id, academicYear);

      setEntityAgreementId(id);

      reset({
        expense_account,
        provider_code,
        payable_account,
        supplier_code,
        supplier_payable_account,
        retention_code,
        transaction_code,
        needs_347,
        course: academicYear,
        agreement_number,
        center_cost_channel_code,
        center_cost_delegation_code,
        center_cost_area_code,
        center_cost_project_code,
        description,
        concept,
        advance_payment,
        advance_payment_date: asingDateValue(advance_payment_date),
        advance_payment_remainder,
        total_confirm_amount,
        total_justified_amount,
        total_paid_amount,
        total_rejected_and_dropped,
        admin_management_amount,
        admin_management_date: asingDateValue(admin_management_date),
        admin_management_sended,
      });
      if (documentations?.length) {
        documentations.forEach((document: DocumentationItem) => {
          document.mandatory = true;
          document.name = tKey('Documento de Convenio');
        });
        setData('agreement_state', agreement_state);
        setAgreementDocumentation(documentations);
        setDatePicker({ advancePayment: !advance_payment_date, admin_management: !admin_management_sended });
      } else {
        setAgreementDocumentation([]);
        setDatePicker({ advancePayment: false, admin_management: false });
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const setAgreement = async (data: InputsData) => {
    try {
      setLoading(true);
      setInputs(data);
      if (data.agreement_number) {
        const { documentations, agreement_state } = await setAgreementByYear({
          id: entity.id,
          academic_year: data.course,
          agreement_number: data.agreement_number,
          center_cost_channel_code: data.center_cost_channel_code || '',
          center_cost_delegation_code: data.center_cost_delegation_code || '',
          center_cost_area_code: data.center_cost_area_code || '',
          center_cost_project_code: data.center_cost_project_code || '',
          description: data.description || '',
          concept: data.concept || '',
          total_confirm_amount: data.total_confirm_amount,
          total_justified_amount: data.total_justified_amount,
          total_paid_amount: data.total_paid_amount,
          total_rejected_and_dropped: data.total_rejected_and_dropped,
          advance_payment: data.advance_payment || 0,
          advance_payment_date: getDateFormatted(data.advance_payment_date),
          advance_payment_remainder: data.advance_payment_remainder || 0,
          admin_management_amount: data.admin_management_amount,
          admin_management_date: getDateFormatted(data.admin_management_date),
        });
        if (documentations?.length) {
          documentations.forEach((document: DocumentationItem) => {
            document.mandatory = true;
            document.name = tKey('Documento de Convenio');
          });
          setAgreementDocumentation(documentations);
        }
        setData('agreement_state', agreement_state);
      }
      if (!data.payable_account) {
        //@ts-ignore
        delete data.payable_account;
      }
      if (!data.provider_code) {
        //@ts-ignore
        delete data.provider_code;
      }
      onSave(data);
      reset({
        course: data.course,
        agreement_number: data.agreement_number,
        center_cost_channel_code: data.center_cost_channel_code,
        center_cost_delegation_code: data.center_cost_delegation_code,
        center_cost_area_code: data.center_cost_area_code,
        center_cost_project_code: data.center_cost_project_code,
        description: data.description,
        concept: data.concept,
        advance_payment: data.advance_payment,
        advance_payment_date: data.advance_payment_date,
        advance_payment_remainder: data.advance_payment_remainder,
        total_confirm_amount: data.total_confirm_amount,
        total_justified_amount: data.total_justified_amount,
        total_paid_amount: data.total_paid_amount,
        total_rejected_and_dropped: data.total_rejected_and_dropped,
        admin_management_amount: data.admin_management_amount,
        admin_management_date: data.admin_management_date,
      });
      setLoading(false);
    } catch (error) {
      //setError('agreement_number', 'notMatch', errorMessage(error));
      showSnackbar(errorMessage(error), 'error', undefined, 3000);
      setLoading(false);
    }
  };

  const { taxRetentions } = genericMetadata;

  useEffect(() => {
    if (retention_code) {
      setSelectedRetentionCode(retention_code);
    } else if (!selectedRetentionCode && natural_person) {
      setSelectedRetentionCode(TaxRetentionCodes.PROFESIONALES);
    } else if (!selectedRetentionCode) {
      setSelectedRetentionCode(TaxRetentionCodes.SIN_RETENCION);
    }
  }, [retention_code, selectedRetentionCode, setSelectedRetentionCode, natural_person]);

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      await fetchGetPayment();
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id && academicYear]);

  const handleCancelAgreement = async () => {
    try {
      const state = await cancelEntityAgreementFromApi(entityAgreementId);
      setData('agreement_state', state);
      showSnackbar(tKey('La asignación se ha realizado correctamente.'), 'success', undefined, 1500);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  useEffect(() => {
    if (!transactionCodesMetadata.length) {
      getAccountingMetadata();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccountingMetadata]);

  useValidatorAPI(validationErrors, setError);

  const getSageData = async () => {
    makeProvider(entity.id);
  };

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
    }
  };

  const handleSelectChangeAcademicYear = (target: any) => {
    setAcademicYear(target.value);
  };

  const advancedImport = async (inputsData: InputsData) => {
    try {
      const advance_date = getDateFormatted(inputsData.advance_payment_date);
      const { advance_payment } = inputsData;
      if (advance_date && advance_payment) {
        const { data: paymentsData } = await checkScholarshipAdvancePayments({
          amount: advance_payment,
          id: entity.id,
          academic_year: watch('course'),
          advance_payment_date: advance_date,
        });
        setPayModal({ view: true, modal: paymentsData });
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const paySage = async (data: PaymentInfo) => {
    try {
      const advance_date = getDateFormatted(watch('advance_payment_date'));
      if (advance_date) {
        const { amount, account_number } = data;
        const {
          data: { advance_payment_remainder, advance_payment_date },
        } = await makeScholarshipAdvancePayments({
          amount,
          id: entity.id,
          academic_year: watch('course'),
          advance_payment_date: advance_date,
          account_number,
        });

        setPayModal(prevModal => ({ ...prevModal, view: false }));

        const advance_payment_date_object = advance_payment_date ? moment(advance_payment_date, 'YYYY-MM-DD') : null;
        setValue('advance_payment_remainder', advance_payment_remainder);
        setValue('advance_payment_date', advance_payment_date_object);
        showSnackbar(tKey('El anticipo se ha realizado correctamente'), 'success', undefined, 1500);
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  const changeRemmant = async (amount: number, comment: string) => {
    try {
      const remaining_amount = await changeEntityRemmant(entity.id, amount, comment);
      setValue('remaining_amount', remaining_amount);
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
    setRemmantModal(false);
  };

  const entityAllowSetAccount = () => {
    try {
      setAllowSetAccount(!allowSetAccount);
      // Desactivamos notificaciones, si las hay, de entidad activada.
      entityProviderInfoChangeFromApi(entity.id);
    } catch (error) {
      console.log(errorMessage(error));
    }
  };

  const onExportScholarshipPaymentsLogs = async () => {
    const csv = await getEntityScholarshipPaymentLogsFromApi(entity.id);
    const fileName = `${entity.id}_importes_becas.xlsx`;
    getDocs(csv, true, fileName);
  };

  const payAdminManagement = async () => {
    try {
      const academic_year = watch('course');
      const amount = watch('admin_management_amount');
      const admin_date = getDateFormatted(watch('admin_management_date'));
      if (admin_date) {
        const res = await payAdminManagementFromApi(entity.id, academic_year, amount, admin_date);
        if (res) {
          setDatePicker({ ...datePicker, admin_management: false });
          showSnackbar(tKey('Gestión administrativa pagada'), 'success', undefined, 1500);
        }
      }
    } catch (error) {
      showSnackbar(errorMessage(error), 'error', undefined, 1500);
    }
  };

  useButtonAction(buttonActionSave, handleSubmit(setAgreement), { hidden: (!buttons && isPma) || disabled });
  useButtonAction(buttonSageProvider, getSageData, { hidden: !(buttons && isCdn) });
  useButtonAction(buttonModifyAccounts, () => entityAllowSetAccount(), { hidden: !(buttons && isCdn) });
  useButtonAction(buttonAdvancedImport, handleSubmit(advancedImport), {
    hidden: !(buttons && isCdn) || !has_scholarship,
    disabled: agreement_state !== ScholarshipAgreementCode.CONFIRM || !datePicker.advancePayment,
  });
  useButtonAction(buttonCancelAgreement, handleCancelAgreement, {
    hidden: !(buttons && isCdn) || !has_scholarship,
    disabled:
      agreement_state === ScholarshipAgreementCode.INIT ||
      agreement_state === ScholarshipAgreementCode.CANCELLED ||
      watch('total_paid_amount') > 0,
  });

  let providerSageView;
  let providerDataView;
  let hasScholarshipView;
  let advancePaymentView;

  if (natural_person || legal_person) {
    providerSageView = (
      <>
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="supplier_code"
          errors={errors}
          value={supplier_code}
          defaultValue={supplier_code}
          label={tFormKey('Código de proveedor')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('supplier_code', e.target.value)}
        />
        <TextInputController
          size="20"
          schema={AccountingDataSchema}
          control={control}
          name="supplier_payable_account"
          errors={errors}
          defaultValue={supplier_payable_account}
          label={tFormKey('Cuenta contable de proveedor')}
          disabled={!allowSetAccount}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('supplier_payable_account', e.target.value)}
        />
      </>
    );
    providerDataView = (
      <div className={accountStyles.supplier_container}>
        <div className={styles.carga_347_checkbox}>
          <CheckboxCompo
            name="needs_347"
            questionText={tFormKey('Carga 347')}
            defaultValue={needs_347 ? 1 : 0 || undefined}
            register={register}
            disabled={disabled || !allowTransactionCodes}
            onChange={(value: boolean) => setData('needs_347', value ? 1 : 0)}
          />
        </div>

        <SelectController
          control={control}
          errors={errors}
          size="20"
          schema={AccountingDataSchema}
          name="payment_type_code"
          disabled={disabled || !allowTransactionCodes}
          defaultValue={payment_type_code}
          label={tFormKey('Tipo de efecto')}
          onClick={e => handleSelectChange(e.target)}
        >
          {paymentTypeCodesMetadata.map(type => (
            <MenuItem key={type.code} value={type.code}>
              {type.name}
            </MenuItem>
          ))}
        </SelectController>

        <SelectController
          control={control}
          errors={errors}
          size="20"
          schema={AccountingDataSchema}
          name="transaction_code"
          disabled={disabled || !allowTransactionCodes}
          defaultValue={transaction_code}
          label={tFormKey('Código de transacción')}
          onClick={e => handleSelectChange(e.target)}
        >
          {transactionCodesMetadata
            .sort((a, b) => a.code - b.code)
            .map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.code} - {type.name}
              </MenuItem>
            ))}
        </SelectController>
        <SelectController
          control={control}
          errors={errors}
          schema={AccountingDataSchema}
          name="retention_code"
          disabled={disabled || !allowTransactionCodes}
          size="30"
          defaultValue={selectedRetentionCode}
          label={tFormKey('Retención')}
          onClick={e => handleSelectChange(e.target)}
        >
          {taxRetentions
            ?.filter(e => e.tax_type === 'R')
            .map(type => (
              <MenuItem key={type.code} value={type.code}>
                {type.name}
              </MenuItem>
            ))}
        </SelectController>
      </div>
    );
  }

  const renderSignatureDocuments = () => {
    if (agreement_state === ScholarshipAgreementCode.CONFIRM) {
      return <SignatureDocuments title={'Addendas convenio'} type={'entity_agreements'} type_id={entityAgreementId} />;
    }
    return undefined;
  };

  if (has_scholarship) {
    let manualRemnantButton;
    const historyIcon = <HistoryIcon />;
    if (allowEntityManualRemnant) {
      manualRemnantButton = (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <Button variant="outlined" color="primary" onClick={() => setRemmantModal(true)}>
          Modificar remanente
        </Button>
      );
    }

    const remnantLogsButton = (
      <Button
        startIcon={historyIcon}
        variant="outlined"
        color="primary"
        className={accountStyles.logButton}
        onClick={() => onExportScholarshipPaymentsLogs()}
      >
        {tFormKey('Historial')}
      </Button>
    );

    if (isNouCims) {
      advancePaymentView = (
        <FormContainer title={tKey('Anticipo de pago')}>
          <div className={accountStyles.advance}>
            <TextInputController
              size="20"
              type="number"
              schema={AccountingDataSchema}
              control={control}
              name="advance_payment"
              placeholder="0"
              defaultValue={watch('advance_payment') || 0}
              errors={errors}
              label={tFormKey('Importe')}
              disabled={!datePicker.advancePayment || disabled}
              price
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('advance_payment', e.target.value)}
            />
            {datePicker.advancePayment ? (
              <KeyboardDatePickerController
                name="advance_payment_date"
                variant="inline"
                inputVariant="outlined"
                label={tFormKey('Fecha de anticipo')}
                format={DATE_FORMAT}
                placeholder="" // Prevent google to detect this input as a credit card number
                className={accountStyles.dateField}
                control={control}
                schema={AccountingDataSchema}
                inputValue={getDateFormatted(watch('advance_payment_date')) || undefined}
                errors={errors}
                disabled={disabled}
                autoOk
              />
            ) : (
              <TextInputController
                size="20"
                name="advance_payment_date"
                control={control}
                label={tFormKey('Fecha de anticipo')}
                errors={errors}
                schema={AccountingDataSchema}
                defaultValue={getDateFormatted(watch('advance_payment_date')) || ''}
                disabled
              />
            )}

            <TextInputController
              size="20"
              schema={AccountingDataSchema}
              control={control}
              type="number"
              name="advance_payment_remainder"
              errors={errors}
              defaultValue={watch('advance_payment_remainder')}
              label={tFormKey('Importe pendiente')}
              disabled
              price
            />
          </div>
        </FormContainer>
      );
    }

    let adminManagementPaymentView;

    if (joined_49_2020) {
      const sendIcon = <Send />;
      let adminManagementButton;
      if (isCdn) {
        adminManagementButton = (
          <Button
            variant="outlined"
            color="primary"
            startIcon={sendIcon}
            disabled={disabled || !datePicker.admin_management}
            onClick={() => payAdminManagement()}
          >
            {tKey('Enviar a SAGE')}
          </Button>
        );
      }

      adminManagementPaymentView = (
        <FormContainer title={tKey('Gestión administrativa')}>
          <div className={accountStyles.advance}>
            <TextInputController
              size="20"
              type="number"
              schema={AccountingDataSchema}
              control={control}
              name="admin_management_amount"
              placeholder="0"
              defaultValue={watch('admin_management_amount') || 0}
              errors={errors}
              label={tFormKey('Importe')}
              disabled={disabled || !datePicker.admin_management || !isNouCims}
              price
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('admin_management_amount', e.target.value)}
            />
            {datePicker.admin_management && isNouCims ? (
              <KeyboardDatePickerController
                name="admin_management_date"
                variant="inline"
                inputVariant="outlined"
                label={tFormKey('Fecha vencimiento')}
                format={DATE_FORMAT}
                placeholder="" // Prevent google to detect this input as a credit card number
                className={accountStyles.dateField}
                control={control}
                schema={AccountingDataSchema}
                inputValue={getDateFormatted(watch('admin_management_date')) || undefined}
                errors={errors}
                disabled={disabled}
                autoOk
              />
            ) : (
              <TextInputController
                size="20"
                name="admin_management_date"
                control={control}
                label={tFormKey('Fecha vencimiento')}
                errors={errors}
                schema={AccountingDataSchema}
                defaultValue={getDateFormatted(watch('admin_management_date')) || ''}
                disabled
              />
            )}
            {adminManagementButton}
          </div>
        </FormContainer>
      );
    }

    hasScholarshipView = (
      <div className={accountStyles.accounting_container}>
        <Title>{tKey('Area contable')}</Title>
        <SelectController
          name="course"
          control={control}
          errors={errors}
          schema={AccountingDataSchema}
          label={tKey('Curso')}
          size="20"
          defaultValue={watch('course')}
          onClick={e => handleSelectChangeAcademicYear(e.target)}
        >
          {academicYears.map(course => (
            <MenuItem key={course} value={course}>
              {course}
            </MenuItem>
          ))}
        </SelectController>
        {isNouCims && (
          <FormContainer title={tKey('Formulario de configuración de pago')}>
            <PaymentSettingsForm
              watch={watch}
              control={control}
              errors={errors}
              genericMetadata={genericMetadata}
              paymentSettingsData={{
                center_cost_channel_code: watch('center_cost_channel_code') || inputs.center_cost_channel_code,
                center_cost_delegation_code: watch('center_cost_delegation_code') || inputs.center_cost_delegation_code,
                center_cost_area_code: watch('center_cost_area_code') || inputs.center_cost_area_code,
                center_cost_project_code: watch('center_cost_project_code') || inputs.center_cost_project_code,
                agreement_number: watch('agreement_number') || inputs.agreement_number,
                description: watch('description') || inputs.description,
                concept: watch('concept') || inputs.concept,
              }}
              disabled={
                disabled ||
                (isCdn && agreement_state === ScholarshipAgreementCode.VALIDATING) ||
                agreement_state === ScholarshipAgreementCode.CONFIRM ||
                !watch('agreement_number')
              }
              seat={agreement_state === ScholarshipAgreementCode.CONFIRM || !watch('agreement_number')}
            />
          </FormContainer>
        )}
        <FormContainer title={tKey('Resumen contable') + ' ' + watch('course')}>
          <div className={accountStyles.accountingSummary_green}>
            <TextInputController
              size="50"
              type="number"
              schema={AccountingDataSchema}
              errors={errors}
              control={control}
              defaultValue={watch('total_confirm_amount') || 0}
              name="total_confirm_amount"
              placeholder="0"
              label={tFormKey('Importe aprobado')}
              price
              disabled
            />
            <TextInputController
              size="50"
              type="number"
              schema={AccountingDataSchema}
              errors={errors}
              control={control}
              defaultValue={watch('total_paid_amount') || 0}
              name="total_paid_amount"
              placeholder="0"
              label={tFormKey('Importe pagado')}
              price
              disabled
            />
          </div>
          <div className={accountStyles.accountingSummary}>
            <TextInputController
              size="50"
              type="number"
              schema={AccountingDataSchema}
              errors={errors}
              control={control}
              defaultValue={watch('total_justified_amount') || 0}
              name="total_justified_amount"
              placeholder="0"
              label={tFormKey('Importe justificado')}
              price
              disabled
            />

            <TextInputController
              size="50"
              type="number"
              schema={AccountingDataSchema}
              errors={errors}
              control={control}
              defaultValue={watch('total_rejected_and_dropped') || 0}
              name="total_rejected_and_dropped"
              placeholder="0"
              label={tFormKey('Abandonos y renuncias')}
              disabled
            />
          </div>
        </FormContainer>
        {advancePaymentView}
        <FormContainer title={tKey('Saldo entidad')}>
          <div className={accountStyles.advance}>
            <TextInputController
              size="20"
              type="number"
              schema={AccountingDataSchema}
              control={control}
              name="remaining_amount"
              placeholder="0"
              defaultValue={entity.remaining_amount}
              errors={errors}
              label={tFormKey('Importe remanente')}
              disabled
              // onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              //   setData('remaining_amount', e.target.value);
              // }}
              price
            />
            <div className={accountStyles.remnantButtons}>
              {manualRemnantButton}
              {remnantLogsButton}
            </div>
          </div>
        </FormContainer>
        {adminManagementPaymentView}
        {(!!agreement_state || watch('agreement_number')) && isNouCims && (
          <>
            <UploadDocuments
              agreement_state={agreement_state}
              agreementDocumentation={agreementDocumentation}
              agreement_number={watch('agreement_number') || ''}
              entityId={entity.id}
            />
            {renderSignatureDocuments()}
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <Loading big />
      ) : (
        <section className={accountStyles.container}>
          <Title>{tKey('Datos contables')}</Title>
          <form className={styles.form}>
            {sendingSage ? (
              <Loading medium />
            ) : (
              <div>
                {isNouCims && (
                  <React.Fragment>
                    <TextInputController
                      size="20"
                      schema={AccountingDataSchema}
                      control={control}
                      name="provider_code"
                      errors={errors}
                      value={provider_code}
                      defaultValue={provider_code}
                      label={tFormKey('Código de proveedor para donaciones')}
                      disabled={!allowSetAccount}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('provider_code', e.target.value)}
                    />
                    <TextInputController
                      size="20"
                      schema={AccountingDataSchema}
                      control={control}
                      name="payable_account"
                      errors={errors}
                      defaultValue={payable_account}
                      label={tFormKey('Cuenta contable de donación')}
                      disabled={!allowSetAccount}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('payable_account', e.target.value)}
                    />
                    <TextInputController
                      size="20"
                      schema={AccountingDataSchema}
                      control={control}
                      name="expense_account"
                      errors={errors}
                      defaultValue={expense_account}
                      label={tFormKey('Cuenta contable de gasto')}
                      disabled={!allowSetAccount}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => setData('expense_account', e.target.value)}
                    />
                  </React.Fragment>
                )}
                {isNouCims && providerSageView}
              </div>
            )}
            {isNouCims && providerDataView}
            {hasScholarshipView}
          </form>
        </section>
      )}
      <Suspense fallback={<></>}>
        <PaymentModal
          view={payModal.view}
          onClose={() => setPayModal(prevModal => ({ ...prevModal, view: false }))}
          onSubmit={paySage}
          inputsData={payModal.modal}
        />
        <RemmantModal
          view={remmantModal}
          remainingAmount={entity.remaining_amount}
          onSubmit={(amount: number, comment: string) => changeRemmant(amount, comment)}
          onClose={() => setRemmantModal(false)}
        />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectValidationErrors(state),
  genericMetadata: selectGenericMetadata(state),
  transactionCodesMetadata: selectTransactionCodes(state),
  paymentTypeCodesMetadata: selectPaymentTypeCodes(state),
  sendingSage: selectSendingSage(state),
  allowTransactionCodes: selectUserCan(state)('allow_transaction_code'),
  allowEntityManualRemnant: selectUserCan(state)('allow_entity_manual_remnant'),
  isNouCims: selectIsNousCims(state),
  academicYears: selectAcademicYears(state),
  currentAcademicYear: selectCurrentAcademicYear(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number) => dispatch(entitySetData(key, value)),
  makeProvider: (id: number): void => dispatch(makeProvider(id)),
  getAccountingMetadata: () => dispatch(getAccountingMetadata()),
  showSnackbar: (message: string, severity: SeveritySnackbar, route: string | undefined, time: number): void =>
    dispatch(showSnackbar(message, severity, route, time)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountingData);
