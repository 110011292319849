import { LoginFields } from '../components/Login/LoginForm/LoginForm';
import { AuthUserDataLogin } from '../redux/auth/definitions';
import { RegisterFields } from '../redux/register/actions';
import { apiCall, APIValidationError, Result, validatedApiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

export const login = async (data: LoginFields): Promise<Result<{ data: AuthUserDataLogin }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return validatedApiCall<{ data: AuthUserDataLogin }>(`${baseURL}/user/login`, init);
};

export const logoutApi = async (): Promise<void> => {
  const init = {
    method: 'POST',
  };

  apiCall(`${baseURL}/user/logout`, init);
};

interface RegisterResult {
  data: object;
}
export const registerUser = async (data: RegisterFields): Promise<Result<RegisterResult, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };
  return validatedApiCall<RegisterResult>(`${baseURL}/user/register`, init);
};

export const setUserAvatar = async (image: string): Promise<string> => {
  const data = { image, filename: 'avatar.png' };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
    auth: true,
  };

  return apiCall<string>(`${baseURL}/user/image`, init);
};

export const getUserAvatar = async (): Promise<{ data: string }> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<{ data: string }>(`${baseURL}/user/image`, init);
};

export const getUserDetails = async (): Promise<string> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  return apiCall<string>(`${baseURL}/user/details`, init);
};

export const authUserChangeRole = async (
  user_id: number,
  role_id: number,
): Promise<Result<{ data: AuthUserDataLogin }, APIValidationError>> => {
  const init = {
    method: 'POST',
    body: JSON.stringify({ user_id, role_id }),
    auth: true,
  };

  return validatedApiCall<{ data: AuthUserDataLogin }>(`${baseURL}/user/change/role`, init);
};
