import { APIResponse } from '../../redux/common/definitions';
import { DirectoryMetadata, QueryUsers, SendSuggestionDataAction } from '../../redux/directory/definitions';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export const getDirectoryMetadataFromApi = async (): Promise<{ data: DirectoryMetadata }> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<{ data: DirectoryMetadata }>(`${baseURL}/user/directory_metadata`, init);
};

export const getDirectoryFilter = async (data?: QueryUsers): Promise<any> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<any>(`${baseURL}/user/directory/`, init);
};

export const sendSuggestionDataApi = async (data: SendSuggestionDataAction): Promise<APIResponse> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<APIResponse>(`${baseURL}/user/suggestion/`, init);
};
