import { AuthUserDataLogin } from '../redux/auth/definitions';
import { apiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

export const accountActivated = async (token: string): Promise<{ data: AuthUserDataLogin }> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(token),
  };

  return await apiCall<{ data: AuthUserDataLogin }>(`${baseURL}/user/verify/${token}`, init);
};
