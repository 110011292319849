import { AreasData, AreasRoleData, SubAreaInfo, Tracking } from '../../redux/area/definitions';
import { IdNamePair } from '../../redux/common/definitions';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export const getAreas = async (): Promise<AreasData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<AreasData>(`${baseURL}/area/`, init);
};
export const getAreasRolesFromApi = async (): Promise<AreasRoleData> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<AreasRoleData>(`${baseURL}/area/role/list/`, init);
};

export const getRolesFromApi = async (): Promise<IdNamePair> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<IdNamePair>(`${baseURL}/roles/`, init);
};
export const addAreasRolesFromApi = async (areaId: number, roleId: number): Promise<{ data: AreasRoleData }> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ area_id: areaId, role_id: roleId }),
  };
  return apiCall<{ data: AreasRoleData }>(`${baseURL}/area/role/add`, init);
};

export const removeAreasRolesFromApi = async (area_id: number, role_id: number): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/area/${area_id}/role/${role_id}`, init);
};

export const editAreaFromApi = async (id: number, data: AreasData): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/area/${id}`, init);
};
export const deleteSubArea = async (id: number): Promise<{ data: 'OK' }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return await apiCall<{ data: 'OK' }>(`${baseURL}/subarea/${id}`, init);
};

export const disableAreaFromApi = async (id: number): Promise<{ data: AreasData }> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };
  return apiCall<{ data: AreasData }>(`${baseURL}/area/${id}`, init);
};

export const addSubarea = async (id: number, data: string): Promise<{ data: SubAreaInfo }> => {
  const dataSend = {
    area_id: id,
    name: data,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(dataSend),
  };
  return apiCall<{ data: SubAreaInfo }>(`${baseURL}/subarea`, init);
};

export const editSubarea = async (id: number, data: string): Promise<{ data: 'OK' }> => {
  const dataSend = {
    name: data,
  };
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(dataSend),
  };
  return apiCall<{ data: 'OK' }>(`${baseURL}/subarea/${id}`, init);
};

export const getTrackingsAreas = async (): Promise<Tracking[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: Tracking[] }>(`${baseURL}/tracking/areas`, init);

  return data;
};

export const createTrackings = async (tracking: Tracking): Promise<Tracking> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(tracking),
  };

  const { data } = await apiCall<{ data: Tracking }>(`${baseURL}/tracking/area/create`, init);

  return data;
};

export const editTrackings = async (tracking: Tracking, id: number | string): Promise<Tracking> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(tracking),
  };

  const { data } = await apiCall<{ data: Tracking }>(`${baseURL}/tracking/area/edit/${id}`, init);

  return data;
};

export const getTrackingsAreaById = async (id: number | string): Promise<Tracking> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: Tracking }>(`${baseURL}/tracking/area/${id}`, init);
  return data;
};

export const getTrackingsByAreaCode = async (code: string): Promise<Tracking[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: Tracking[] }>(`${baseURL}/tracking/by_area/${code}`, init);
  return data;
};

export const deleteTracking = async (id: number): Promise<Tracking[]> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  const { data } = await apiCall<{ data: Tracking[] }>(`${baseURL}/tracking/area/${id}`, init);

  return data;
};

export const storeArea = async (name: string): Promise<{ data: AreasData }> => {
  const dataSend = {
    name,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(dataSend),
  };
  return apiCall<{ data: AreasData }>(`${baseURL}/area`, init);
};

export const getInternalProjectTemplateRolesOfArea = async (area_id: number): Promise<IdNamePair[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  const { data } = await apiCall<{ data: IdNamePair[] }>(`${baseURL}/area/${area_id}/roles`, init);
  return data;
};
