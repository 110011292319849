import { SearchItem } from '../../redux/searchBar/definitions';
import { apiCall } from '../api';

const baseURL = process.env.REACT_APP_API_URL;

export const getSearchResponse = async (query: string) => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SearchItem[]>(`${baseURL}/search/${query}`, init);
};

export const searchRelationalData = async (type: string, id: number) => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return await apiCall<SearchItem[]>(`${baseURL}/search/${type}/${id}`, init);
};
