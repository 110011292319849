import { ListMails, MailDetails, TranslationKeyData } from '../redux/translations/definitions';
import { apiCall, apiCallBlob } from './api';

const baseURL = process.env.REACT_APP_API_URL;

export const getTranslationKeys = async (language: string): Promise<TranslationKeyData[]> => {
  const init = {
    method: 'GET',
    auth: false,
  };
  const { data } = await apiCall<{ data: TranslationKeyData[] }>(`${baseURL}/translation_keys/${language}`, init);
  return data;
};

export const getFileTranslation = async (source: 'keys' | 'custom_fields'): Promise<Blob> => {
  const init = {
    method: 'POST',
    auth: true,
  };
  if (source === 'custom_fields') {
    return await apiCallBlob(`${baseURL}/translation_keys/custom_fields/export`, init);
  } else {
    return await apiCallBlob(`${baseURL}/translation_keys/export`, init);
  }
};

export const sendTranslationImport = async (source: 'keys' | 'custom_fields', translationObj: any): Promise<Blob> => {
  const data = {
    translations: translationObj,
  };
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };
  if (source === 'custom_fields') {
    return await apiCallBlob(`${baseURL}/translation_keys/custom_fields/import`, init);
  } else {
    return await apiCallBlob(`${baseURL}/translation_keys/import`, init);
  }
};

export const getMailsListApi = async (): Promise<ListMails[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: ListMails[] }>(`${baseURL}/translation_mails/list`, init);
  return data;
};

export const getMailDetailsApi = async (mailCode: string): Promise<ListMails[]> => {
  const init = {
    method: 'GET',
    auth: true,
  };
  const { data } = await apiCall<{ data: ListMails[] }>(`${baseURL}/translation_mails/item/${mailCode}`, init);
  return data;
};

export const updateMailDetailsApi = async (mailData: MailDetails): Promise<any> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(mailData),
  };

  const { data } = await apiCall<any>(`${baseURL}/translation_mails/item`, init);

  return data;
};
