import { Assistance, InternalProjectId, Participant } from '../../../redux/internal-projects/participants/Participant';
import { apiCall } from '../../api';

const baseURL = process.env.REACT_APP_API_URL;

export interface ParticipantsResponse {
  data: Participant[];
}

export const getParticipants = async (id: InternalProjectId): Promise<ParticipantsResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return (await apiCall)<ParticipantsResponse>(`${baseURL}/internal_project/${id}/participants`, init);
};

export interface ParticipantsWithAssistanceResponse {
  data: Assistance[];
}

export const getParticipantsWithAssistance = async (
  followUpId: number,
): Promise<ParticipantsWithAssistanceResponse> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return (await apiCall)<ParticipantsWithAssistanceResponse>(
    `${baseURL}/internal_project/follow_up/${followUpId}/participants`,
    init,
  );
};

export const updateParticipantsAssistanceApi = async (infoUpdate: Assistance): Promise<void> => {
  const init = {
    method: 'POST',
    body: JSON.stringify(infoUpdate),
    auth: true,
  };
  await apiCall<{ data: void }>(`${baseURL}/internal_project/follow_up/participants/assistance`, init);
};
