import { apiCall } from './api';

const baseURL = process.env.REACT_APP_API_URL;

interface RecoverPasswordProps {
  email: string;
}

interface RecoverResult {
  data: RecoverPasswordProps[];
}
export const recoverEmail = async (email: string): Promise<RecoverResult> => {
  const data = { email: email };
  const init = {
    method: 'POST',
    body: JSON.stringify(data),
  };

  return await apiCall<RecoverResult>(`${baseURL}/user/forgot-password/`, init);
};
