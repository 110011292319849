import { useForm } from 'react-hook-form';
import { tErrorKey } from '../helpers/translate';

export const validateFollowupJustification = (
  confirmed: number,
  justified: number,
  totalJustified: number,
  fieldName: string,
  setError: ReturnType<typeof useForm>['setError'],
  clearError: ReturnType<typeof useForm>['clearError'],
) => {
  if (justified > confirmed - totalJustified) {
    setError(fieldName, 'invalidNumber', tErrorKey('importe-invalido'));
  } else {
    clearError(fieldName);
  }
};
